export const setCookie = (name: string, value: string, expireAt: Date) => {
  document.cookie = `${name}=${value}; Domain=.kangaroo.com.br; Path=/; SameSite=Lax; expires=${expireAt.toUTCString()};Secure`;
};

export const getCookie = (cookieName: string) => {
  if (document.cookie.length) {
    const values = document.cookie.split('; ');
    for (let i in values) {
      let cookie = values[i].split('=');
      let name = cookie[0];
      let value = cookie[1];
      if (name === cookieName) {
        return value;
      }
    }
  }
  return null;
};
