import React, { useEffect, useState } from 'react';
import { Element } from './CookieConsent.styles';
import Text from '@/components/typography/Text/Text';
import Button from '@/components/button/Button/Button';
import Link from '@/providers/Link';
import { getCookie, setCookie } from '@/utils/cookie';
import dayjs from 'dayjs';

export interface CookieConsentProps {}

const COOKIE_CONSENT_NAME = 'cookieconsent_status';

const CookieConsent = ({ ...props }: CookieConsentProps) => {
  const [visible, setVisible] = useState<'show' | 'hide' | 'dismiss' | null>(null);

  const handleClose = () => {
    document.cookie = `${COOKIE_CONSENT_NAME}=DISMISS; Domain=kangaroo.com.br; SameSite=Lax; Secure`;
    setCookie(COOKIE_CONSENT_NAME, 'DISMISS', dayjs().add(1, 'year').toDate());
    console.log(dayjs().add(1, 'year').toDate().toUTCString());
    setVisible(() => 'hide');
  };

  useEffect(() => {
    if (typeof document.cookie !== undefined) {
      const consentCookie = getCookie(COOKIE_CONSENT_NAME);
      if (consentCookie === 'DISMISS') {
        return;
      }

      setVisible(() => 'show');
    }
  }, []);

  if (visible === null) return null;

  return (
    <Element visible={visible}>
      <Text size="sm">
        Utilizamos cookies para oferecer melhor experiência, melhorar o desempenho, analisar como você interage em nosso
        site e personalizar conteúdo. Ao utilizar este site, você concorda com o uso de cookies.{' '}
        <Link href="/politica-de-privacidade.html">Saiba mais</Link>
      </Text>
      <Button onClick={handleClose}>Ok, Entendi.</Button>
    </Element>
  );
};

export default CookieConsent;
