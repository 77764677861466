import styled, { css } from 'styled-components';

export const Element = styled.div<{ visible: 'show' | 'hide' | 'dismiss' | null }>`
  ${({ theme, visible, ...props }) => {
    return css`
      position: fixed;
      max-width: 18rem;
      background: ${theme.color.white};
      bottom: 0.5rem;
      left: 0.5rem;
      padding: ${theme.spacing.base};
      z-index: 4000;

      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: self-start;

      border-radius: ${theme.radius.sm};

      a {
        text-decoration: underline;
      }

      box-shadow: ${theme.box_shadow.default};

      transform: translateX(0);
      transition: transform 400ms linear;
      ${visible === 'hide' &&
      css`
        transform: translateX(-120%);
      `};
    `;
  }};
`;
